<template>
  <b-form v-if="isSuperuser">
    <b-form-group :label="$t('ImportPromotionalCodes')">
      <b-row>
        <b-col md="8">
          <b-form-file
            type="file"
            accept=".csv"
            :browse-text="$t('formFile.browse')"
            :placeholder="$t('formFile.placeHolder')"
            @change="handleFileUpload"
          />
        </b-col>
        <b-col>
          <b-button
            :disabled="csvData.length === 0"
            variant="outline-warning"
            @click="confirm"
          >
            {{ $t('dataGeneric.import') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form-group>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormFile,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'
import axios from '@axios'
import { utf8ToB64, showToast } from '@/store/functions'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormFile,
  },
  props: {
    isSuperuser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      csvData: [],
      success: false,
    }
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0]
      if (!file) return

      const reader = new FileReader()
      reader.onload = e => {
        const text = e.target.result
        this.csvData = this.parseCSV(text)
      }
      reader.readAsText(file)
    },
    parseCSV(csvText) {
      const lines = csvText.split('\n')
      const headers = lines[0].split(',').map(header => header.trim())

      const data = lines.slice(1)
        .map(line => {
          const values = line.split(',').map(value => value.trim())
          return headers.reduce((obj, header, index) => {
            // eslint-disable-next-line no-param-reassign
            obj[header] = values[index] || '' // Assign empty string if undefined
            return obj
          }, {})
        }).filter(row => row.code)
      return data
    },
    async confirm() {
      if (!this.isSuperuser) return
      this.$swal({
        title: this.$t('code.title'),
        text: this.csvData.length >= 100 ? this.$t('ImportPromotionalCodesConfirm', { number: this.csvData.length }) : this.$t('ImportPromotionalCodesConfirmNoBatch', { number: this.csvData.length }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.createPromotionalCodes()
        } else {
          this.success = false
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async createPromotionalCodes() {
      try {
        this.$emit('proccessing')
        const batchSize = 100
        const batches = []

        for (let i = 0; i < this.csvData.length; i += batchSize) {
          batches.push(this.csvData.slice(i, i + batchSize))
        }
        await this.processBatch(batches).then(() => {
          this.success = true
          showToast(this.$t('success'), 1, this)
          this.$emit('success')
        }).catch(() => {
          this.success = false
          this.$emit('error')
          showToast(this.$t('error'), 2, this)
        })
      } catch (error) {
        this.success = false
        this.$emit('error')
        showToast(this.$t('error'), 2, this)
      }
    },
    async processBatch(batches, currentBatchIndex = 0) {
      if (currentBatchIndex >= batches.length) return
      const batch = batches[currentBatchIndex]
      let query = 'mutation {'
      batch.forEach((row, index) => {
        if (!row.code) return
        const clientId = utf8ToB64(`client:${row.client}`)
        query += `
          m${index + 1}: createPromotionalcode(input: {
            code: "${row.code.toUpperCase()}",
            client: "${clientId}",
            description: "${row.description || ''}",
            isActive: ${row.is_active === '1'},
            isReusable: ${row.is_reusable === '1'},
            promotionType: ${row.promotion_type},
            allowedUses: ${parseInt(row.allowed_uses, 10) || null},
            expirationDate: ${row.expiration_date ? `"${row.expiration_date}"` : null},
            allowedUsers: ${row.allowed_users ? `[${row.allowed_users.split(',').map(id => `"${id}"`)}]` : '[]'},
            categories: ${row.categories ? `[${row.categories.split(',').map(id => `"${id}"`)}]` : '[]'},
            contents: ${row.contents ? `[${row.contents.split(',').map(id => `"${id}"`)}]` : '[]'},
            subscription: "${row.subscription}",
            campaign: "${row.campaign}",
            ${row.is_session_code ? `, isSessionCode: ${row.is_session_code === '1'}` : ''}
          }) {
            promotionalCode {
              id
              code
              expirationDate
              isActive
            }
          }
        `
      })
      query += '}'

      await axios.post('', { query }).then(response => {
        if (response.data.errors) {
          throw new Error(response.data.errors)
        }
        if (currentBatchIndex + 1 < batches.length) showToast(this.$t('PromotionalCodesBatchUploaded'), 1, this)
        return this.processBatch(batches, currentBatchIndex + 1)
      }).catch(error => {
        showToast(`Error processing batch ${currentBatchIndex}`, 2, this)
        console.error(`Error processing batch ${currentBatchIndex}:`, error)
        throw error
      })
    },
  },
}
</script>
